.sectionContent {
  display: flex;
  /* padding: 0px 0px 40px 0px; */
  flex-direction: column;
  align-items: flex-start;
  gap: 35px;
  align-self: stretch;
}

.title {
  color: var(--Neutrals-1, #141416);
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.pageDescContainer {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 40px;
}
.pageDescContainer img {
  width: 250px;
  height: 160px;
  flex-shrink: 0;
}
.pageDescTextContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  padding-right: 100px;
}
.pageDescTextContent h2 {
  color: var(--Neutrals-1, #141416);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.pageDescTextContent p {
  align-self: stretch;
  color: var(--Neutrals-4, #777e90);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  letter-spacing: -0.065px;
}

.line {
  display: block;
  width: 100%;
  height: 1px;
  background: #e6e8ec;
}
.sectionTitle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}
.sectionTitleRow {
  display: flex;
  width: 100%;
  height: 36px;
  justify-content: space-between;
  align-items: center;
}
.sectionTitleRow h2 {
  color: var(--Neutrals-1, #141416);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.taggingContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}
.taggingTop {
  display: flex;
  align-items: stretch;
  gap: 20px;
  align-self: stretch;
  height: 36px;
}
.taggingSearchContainer {
  display: flex;
  padding: 6px 10px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 6px;
  border: 1px solid var(--Neutrals-8, #e6e8ec);
  background: var(--Neutrals-11-White, #fff);
}
.taggingSearchContainer input {
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.075px;
  width: 100%;
}
.taggingSearchContainer input::placeholder {
  color: var(--Neutrals-6, #b1b5c3);
}
.taggingSearchContainer::before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.31199 7.24795C3.31199 5.07418 5.07418 3.31199 7.24795 3.31199C9.42173 3.31199 11.1839 5.07418 11.1839 7.24795C11.1839 9.42173 9.42173 11.1839 7.24795 11.1839C5.07418 11.1839 3.31199 9.42173 3.31199 7.24795ZM7.24795 2C4.34959 2 2 4.34959 2 7.24795C2 10.1463 4.34959 12.4959 7.24795 12.4959C8.46276 12.4959 9.58116 12.0831 10.4706 11.3902C10.488 11.413 10.5071 11.4349 10.528 11.4557L12.8802 13.8079C13.1364 14.0641 13.5518 14.0641 13.8079 13.8079C14.0641 13.5518 14.0641 13.1364 13.8079 12.8802L11.4557 10.528C11.4349 10.5071 11.413 10.488 11.3902 10.4706C12.0831 9.58116 12.4959 8.46276 12.4959 7.24795C12.4959 4.34959 10.1463 2 7.24795 2Z' fill='%23B1B5C3'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 16px;
  height: 16px;
}
.sortOptionsListSelect {
  border-radius: 8px;
  border: 1px solid var(--Neutrals-8, #e6e8ec);
  background: var(--Neutrals-11-White, #fff);
  box-shadow: 0px 30px 40px 0px rgba(15, 15, 15, 0.1);
}
.sortOptionsListSelect ul {
  display: flex;
  padding: 5px;
  flex-direction: column;
  align-items: flex-start;
}
.sortOptionsListSelect ul li button {
  display: flex;
  height: 36px;
  padding: 10px 12px;
  align-items: center;
  gap: 12px;
  color: var(--Neutrals-4, #777e90);
  width: max-content;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.15px;
}
.sortOptionsListSelect ul li button::before {
  content: "";
  width: 16px;
  height: 16px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.sortOptionsListSelect ul li[data-selected="true"] button {
  color: var(--Neutrals-1, #141416);
}
.sortOptionsListSelect ul li[data-selected="true"] button::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cg clip-path='url(%23clip0_3146_21170)'%3E%3Cpath d='M8 0C3.58172 0 0 3.58172 0 8C0 12.4182 3.58172 16 8 16C12.4182 16 16 12.4182 16 8C16 3.58172 12.4182 0 8 0Z' fill='%23353945'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.7074 5.2929C12.0979 5.68344 12.0979 6.31664 11.7074 6.70716L7.70722 10.7073C7.31671 11.0978 6.68349 11.0978 6.29298 10.7073L4.2929 8.70722C3.90237 8.31671 3.90237 7.68349 4.2929 7.29298C4.68344 6.90246 5.31662 6.90246 5.70717 7.29298L7.0001 8.58592L10.2931 5.2929C10.6836 4.90237 11.3168 4.90237 11.7074 5.2929Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_3146_21170'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
}

.taggingTopOptions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
}
.taggingSortOptionsContainer>div>button{
  border-radius: 6px;
  border: 1px solid var(--Neutrals-8, #E6E8EC) !important;
  
  background: var(--Neutrals-11-White, #FFF) !important;
  /* Elevation 3 */
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
}
.taggingSortOptionsContainer>div>button[data-dropdown-opened="true"]{
  background: var(--Neutrals-9, #F4F5F6) !important;
}
.taggingSortOptionsContainer button *{
  color: var(--Neutrals-3, #353945) !important;
  fill: #353945 !important;
  stroke: #353945 !important;
}
.taggingSortOptionsContainer button [icon='arrowTop'] path{
  fill: none !important;
}
.taggingTopActions {
  display: flex;
  align-items: center;
  gap: 20px;
}
.taggingTopActions span {
  color: var(--Neutrals-4, #777e90);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.taggingTableContainer {
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--Neutrals-8, #e6e8ec);
  background: var(--Neutrals-11-White, #fff);
}
.taggingTableContainer .taggingTablePlaceHolder {
  display: none;
}
.taggingTableContainer:has(tbody:empty) {
  position: relative;
}
.taggingTableContainer:has(tbody:empty) .taggingTablePlaceHolder {
  display: block;
  display: flex;
  padding: 80px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  position: absolute;
  top: -1px;
  left: -1px;
  border-radius: 8px;
  border: 1px solid var(--Neutrals-8, #e6e8ec);
  background: var(--Neutrals-11-White, #fff);
  width: calc(100% + 2px);
}
.taggingTablePlaceHolder::before {
  content: "";
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 48px;
  height: 48px;
  background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_2943_49268)'%3E%3Cpath d='M7.5232 42.1905H26.3301C26.2951 41.9633 26.2601 41.7361 26.2427 41.4914L24.4249 22.6845C24.1802 19.9753 26.0329 17.8255 28.7246 17.8255H33.4613C33.4263 16.8642 33.269 16.4097 32.7796 15.7455L29.3713 11.2361V8.21232C29.3713 5.50315 27.7983 4 24.9842 4H10.5295C7.71547 4 6.1424 5.50315 6.1424 8.21232V11.2361L2.7341 15.7455C2.17478 16.5321 2 17.1089 2 18.4023V36.7197C2 40.3378 3.88768 42.1905 7.5232 42.1905ZM13.0639 10.9739C12.4172 10.9739 11.8753 10.4146 11.8753 9.75042C11.8753 9.10372 12.4172 8.54441 13.0639 8.54441H22.4149C23.0616 8.54441 23.6034 9.10372 23.6034 9.75042C23.6034 10.4146 23.0616 10.9739 22.4149 10.9739H13.0639ZM32.7971 45.2143H40.7673C43.2667 45.2143 44.5252 43.9558 44.7874 41.2292L46.6051 22.4398C46.7275 21.1988 46.0108 20.3773 44.8223 20.3773H38.3727L38.4252 19.6433C38.4951 18.8043 38.7223 18.3324 39.3166 17.9129L45.1719 13.718C46.675 12.6344 45.067 10.3272 43.459 11.4983L37.8484 15.5533C36.4501 16.567 35.8384 17.6857 35.6811 19.4685L35.6111 20.3773H28.7246C27.5536 20.3773 26.8369 21.2163 26.9593 22.4398L28.7771 41.2292C29.0392 43.9558 30.2802 45.2143 32.7971 45.2143Z' fill='%23959BAB'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_2943_49268'%3E%3Crect width='48' height='48' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}
.taggingTablePlaceHolder h3 {
  color: var(--Neutrals-5, #959bab);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 160% */
  letter-spacing: -0.075px;
}
.taggingTablePlaceHolder p {
  color: var(--Neutrals-5, #959bab);
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  letter-spacing: -0.065px;
}
.taggingTableContainer table {
  border-collapse: collapse;
  width: 100%;
}
.taggingTableContainer table thead td {
  height: 50px;
  padding: 15px 10px 15px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  color: var(--Neutrals-5, #959bab);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.065px;
}
.productRow {
  border-top: 1px solid var(--Neutrals-8, #e6e8ec);
}
.productRow td {
  height: 70px;
  padding: 15px 10px 15px 20px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.productRow [data-dropdown-opened] path {
  stroke: #777e90 !important;
}
.productRow [data-dropdown-opened] {
  border-radius: 6px;
  background: var(--Neutrals-11-White, #fff);
}
.productRow [data-dropdown-opened="true"] {
  border: 1px solid var(--Neutrals-3, #353945) !important;
}
.productRowProductName {
  color: var(--Neutrals-3, #353945);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.15px;
  word-break: break-all;
}
.productRowTags {
  display: flex;
  align-items: flex-start;
  gap: 5px;
  overflow: hidden;
}
.productRowTagBody {
  display: flex;
  height: 16px;
  padding: 2px 4px 2px 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 3px;
  color: var(--Neutrals-11-White, #fff);
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.22px;
  text-transform: uppercase;
  user-select: none;
}
.productRowActions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
.tagListBody {
  display: inline-flex;
  min-width: 240px;
  max-height: 250px;
  padding: 5px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: var(--Neutrals-11-White, #fff);

  /* Elevation 1 */
  box-shadow: 0px 30px 40px 0px rgba(15, 15, 15, 0.1);
}
.tagListButtons {
  display: flex;
  padding: 5px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}
.tagListContainer {
  overflow: auto;
  width: 100%;
}

/* для Chrome/Edge/Safari */
.tagListContainer::-webkit-scrollbar {
  height: 12px;
  width: 12px;
}
.tagListContainer::-webkit-scrollbar-track {
  border-radius: 20px;
  background: var(--Neutrals-9, #f4f5f6);
}
.tagListContainer::-webkit-scrollbar-thumb {
  border-radius: 11px;
  background: var(--Neutrals-5, #959bab);
  width: 8px;
}
.tagList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
}
.tagList li {
  display: flex;
  padding: 10px 12px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 6px;
  cursor: pointer;
}
.tagList li span {
  transition: 0.2s;
}
.tagList li:hover span {
  transform: translateX(5px);
}
.tagList li::before {
  content: "";
  background-image: var(--checkbox);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 16px;
  height: 16px;
}
.tagList li[data-is-selected="true"]::before {
  background-image: var(--checkbox-checked);
}
.productRowNewNameContainer {
  display: flex;
  align-items: stretch;
  gap: 10px;
  align-self: stretch;
}
.productRowNewNameContainer input {
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.15px;
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid var(--Neutrals-8, #e6e8ec);
  background: var(--Neutrals-11-White, #fff);
}
.productRowNewNameContainer input::placeholder {
  color: var(--Neutrals-6, #b1b5c3);
}
.productRowNewNameContainer button {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
  width: 38px;
}
.renameProductBody {
  display: flex;
  width: 600px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  padding: 30px 30px 0;
}
.renameProductBody h2 {
  color: var(--Neutrals-1, #141416);
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.renameProductInputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.renameProductInputContainer label {
  color: var(--Neutrals-5, #959bab);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.065px;
}
.renameProductInputContainer input {
  height: 36px;
  padding: 10px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid var(--Neutrals-8, #e6e8ec);
  background: var(--Neutrals-11-White, #fff);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.renameProductButtons {
  display: flex;
  padding: 20px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-top: 1px solid var(--Neutrals-9, #f4f5f6);
  background: var(--Neutrals-11-White, #fff);
}
