.page {
  display: flex;
  padding: 0px 20px 40px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 35px;
  align-self: stretch;
}
.title {
  color: var(--Neutrals-1, #141416);
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.pageDescContainer {
  display: flex;
  width: 1026px;
  align-items: center;
  gap: 40px;
}
.pageDescContainer img {
  width: 250px;
  height: 160px;
}
.pageDescContainer p {
  color: var(--Neutrals-4, #777e90);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  letter-spacing: -0.065px;
}
.updatesList {
  display: flex;
  padding-top: 50px;
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
  align-self: stretch;
}
.updatesListRow {
  display: flex;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
}
.updatesListRowTitle {
  display: flex;
  width: 250px;
  flex-direction: column;
  align-items: flex-start;
}
.updatesListRowTitle h3 {
  color: var(--Neutrals-1, #141416);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 160% */
  letter-spacing: -0.075px;
}
.updatesListRowTitle p {
  color: var(--Neutrals-4, #777e90);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  letter-spacing: -0.065px;
}

.updatesListRowContent{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    flex: 1;
    max-width: 500px;
}
.updatesListRowContent *{
    color: var(--Neutrals-4, #777E90);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 153.846% */
    letter-spacing: -0.065px;
}
.updatesListRowContent strong{
    font-weight: 600;
}
.updatesListRowContent ul{
    padding-left: 20px;
}
.updatesListRowContent li{
    list-style: disc;
}

.updatesListRowContent h2,.updatesListRowContent h3,.updatesListRowContent h1{
    color: var(--Neutrals-1, #141416);
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 160% */
letter-spacing: -0.075px;
}
.updatesListRowContent h1{
  font-size: 24px;
}
.updatesListRowContent h2{
    font-size: 18px;
    font-weight: 800;
    margin-top: 10px;
}
.updatesListRowContent h3{
    font-weight: 600;
}