@import url(./fonts/stylesheet.css);
*,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
button,
input hr a {
  background: none;
  border: none;
  outline: none;
  box-sizing: border-box;
  border: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  color: var(--tg-theme-text-color);
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  -webkit-tap-highlight-color: transparent;
}
:root{
  --checkbox: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cg clip-path='url(%23clip0_3129_10719)'%3E%3Crect opacity='0.25' x='1' y='1' width='14' height='14' rx='4' stroke='%23959BAB' stroke-width='2'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_3129_10719'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  --checkbox-checked: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cg clip-path='url(%23clip0_3129_19873)'%3E%3Crect width='16' height='16' rx='5' fill='%23353945'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.7074 5.2929C12.0979 5.68344 12.0979 6.31664 11.7074 6.70716L7.70722 10.7073C7.31671 11.0978 6.68349 11.0978 6.29298 10.7073L4.2929 8.70722C3.90237 8.31671 3.90237 7.68349 4.2929 7.29298C4.68344 6.90246 5.31662 6.90246 5.70717 7.29298L7.0001 8.58592L10.2931 5.2929C10.6836 4.90237 11.3168 4.90237 11.7074 5.2929Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_3129_19873'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
}
body:has(.modal-bg) {
  overflow: hidden !important;
}
body:not(:has(#auth)),
html:not(:has(#auth)),
#root:not(:has(#auth)){
  background: var(--Neutrals-10, #fcfcfd);
  min-width: 1550px;
}
button {
  cursor: pointer;
  transition: 0.2s;
  position: relative;
}
button:disabled{
  cursor: not-allowed;
}

button[data-black="true"] {
  display: flex;
  padding: 10px 12px;
  align-items: center;
  gap: 10px;
  color: var(--Neutrals-11-White, var(--White, #fff));
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.075px;
  border-radius: 6px;
  border: 1px solid var(--Neutrals-1, #141416);
  background: var(--Neutrals-3, #353945);
  
  /* Elevation 3 */
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
}
button[data-black="true"] .black-path-fill{
  fill: white;
}
button[data-white="true"] {
  border-radius: 6px;
  border: 1px solid var(--Neutrals-8, #e6e8ec);
  background: var(--White, #fff);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  padding: 10px 12px;
  align-items: center;
  gap: 5px;
  color: var(--Neutrals-3, #353945);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.065px;
}
button[data-red="true"] {
  display: flex;
  height: 36px;
  padding: 10px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid var(--Secondary-Red-Hover, #CD1743);
  background: var(--Seconday-Red, #EE2D5C);
  
  /* Elevation 3 */
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  color: var(--Neutrals-11-White, #FFF);
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.075px;
}
button:disabled {
  border-radius: 6px;
  border: 1px solid var(--Neutrals-7, #d5d8de);
  background: var(--Neutrals-8, #e6e8ec);
  color: var(--Neutrals-5, #959bab);
}
button:disabled path{
  fill: var(--Neutrals-5, #959bab);
  stroke: var(--Neutrals-5, #959bab);
}
.loader {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
  margin: 30px auto;
}
.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 3px solid #777E90;
  animation: prixClipFix 2s linear infinite ;
}
@keyframes rotate {
  100%   {transform: rotate(360deg)}
}
@keyframes prixClipFix {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
    100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
}

.hint-body{
  display: flex;
  padding: 10px 15px !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 6px;
  background: var(--Neutrals-2, #23262F);
  color: var(--Neutrals-11-White, #FFF) !important;
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.065px;
  position: absolute;
  width: max-content;
  left: 50%;
  z-index: 10;
  pointer-events: none;
}

.hint-body p{
  color: var(--Neutrals-11-White, #FFF) !important;
  padding: 0 !important;
  height: auto !important;
}

.hint-body span{
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg width='15' height='7' viewBox='0 0 15 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.08579 5.58579C6.86684 6.36684 8.13317 6.36683 8.91421 5.58579L14.5 -4.76837e-07H0.5L6.08579 5.58579Z' fill='%2323262F'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  width: 14px;
  height: 7px;
  background-position: center;
  background-size: contain;
  position: absolute;
  bottom: 1px;
  left: 50%;
  pointer-events: none;
}
.hint-body[data-error-hint="true"]{
  background: var(--Seconday-Red, #EE2D5C);
}
.hint-body[data-error-hint="true"] span{
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='7' viewBox='0 0 14 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.41421 5.58579C7.63316 6.36684 6.36683 6.36683 5.58579 5.58579L8.91839e-07 -1.56665e-06L14 -3.42727e-07L8.41421 5.58579Z' fill='%23EE2D5C'/%3E%3C/svg%3E%0A");
}
.hint-body[data-bottom-hint="true"] span{
  bottom: auto;
  top: 1px;
}
.btn-icon-wrapper{
  display: flex;
  position: relative;
  overflow: hidden;
}
.flex {
  display: flex;
  align-items: center;
  gap: 5px;
}
.page-wrapper {
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
}
.section-wrapper {
  overflow: unset;
}
.notification-container {
  position: fixed;
  width: 0;
  height: 0;
  z-index: 100;
  /* display: flex;
  flex-direction: column-reverse; */
}
.custom-notification {
  position: fixed;
  height: 38px;
  top: 15px;
  left: 50%;
  border-radius: 8px;
  border: 1px solid var(--Neutrals-1, #141416);
  background: var(--Neutrals-1, #141416);

  /* Elevation 1 */
  box-shadow: 0px 30px 40px 0px rgba(15, 15, 15, 0.1);
}
.custom-notification output {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.custom-notification output svg{
  width: 16px !important;
  height: 16px !important;
}
.custom-notification p {
  color: var(--Neutrals-11-White, #fff);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  flex: 1;
  letter-spacing: -0.075px;
}

.navigation {
  display: flex;
  width: 300px;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 73px;
  flex-shrink: 0;
  align-self: stretch;
  border-right: 1px solid var(--Neutrals-8, #e6e8ec);
  position: sticky;
  top: 0;
  height: 100vh;
}
.navigation nav {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 73px;

}
.nav-title{
  color: var(--Neutrals-5, #959BAB);
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.15px;
}
.navigation nav ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
}
.navigation nav ul:has(> li.nav-title:only-child) {
  display: none;
}
.navigation nav ul li:not(.nav-title){
  width: 100%;
  opacity: 0.5;
}
.navigation nav ul li.current {
  opacity: 1;
}
.navigation nav ul li a {
  display: flex;
  align-items: center;
  gap: 20px;
  flex: 1 0 0;
  color: var(--Neutrals-1, #141416);
  /* color: var(--Neutrals-5, #959BAB); */
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.15px;
}

.top-bar {
  width: 100%;
  display: flex;
  height: 60px;
  padding: 0px 20px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.top-bar-drop-down {
  display: flex;
  height: 36px;
  max-width: 200px;
  padding: 10px 12px;
  align-items: center;
  filter: opacity(0.8);
}

.top-bar-drop-down p {
  overflow: hidden;
  color: var(--Neutrals-3, #353945);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.15px;
}
.top-bar-drop-down span {
  width: 16px;
  height: 16px;
}
.top-bar-drop-down:hover {
  filter: opacity(1);
}
.drop-down-body {
  display: flex;
  min-width: 240px;
  padding: 5px;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  right: 20px;
  top: 58px;
  border-radius: 8px;
  background: var(--White, #fff);
z-index: 5;
  /* Elevation 1 */
  box-shadow: 0px 30px 40px 0px rgba(15, 15, 15, 0.1);
}
/* SWITCH */
.switch-body {
  display: flex;
  align-items: center;
  padding: 2px;
  background-color: #b1b5c3;
  width: 26px;
  height: 18px;
  border-radius: 10px;
  position: relative;
  transition: 0.2s;
}
.switch-body[data-checked="true"] {
  justify-content: end;
  background-color: #353945;
}
.switch-body[data-locked="true"] {
  opacity: 0.5;
  cursor: default;
}
.switch-body[data-locked="true"][data-checked="true"] {
  background-color: #b1b5c3 !important;
  opacity: 1;
}
.switch-body::after {
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  width: calc(100% + 10px);
  height: calc(100% + 10px);
}
.switch-circle {
  transition: 0.2s;
  background-color: white;
  width: 14px;
  height: 14px;
  /* height: 100%; */
  border-radius: 50%;
}
/* DROP ZONE */

.drop-down-body li {
  cursor: pointer;
  display: flex;
  height: 36px;
  padding: 10px 12px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  color: var(--Neutrals-4, #777e90);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.15px;
}
.drop-down-body li:hover {
  filter: brightness(0);
}
.drop-zone-container {
  transition: 0.2s;
  display: flex;
  padding: 0px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid var(--Neutrals-8, #e6e8ec);
  background: var(--Neutrals-9, #f4f5f6);
  position: relative;
}
.drop-zone-loading{
  position: absolute;
  top: 10px;
  right: 10px;
}
.drop-zone-container.drag-over {
  transform: scale(0.97);
  border: 1px solid var(--Neutrals-8, #cbcdd1);
  background: var(--Neutrals-9, #ebebeb);
}
.drop-zone {
  cursor: pointer;
  display: flex;
  height: 150px;
  padding: 40px 0px;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 100%;
}
.drop-zone:has(.uploaded-photo) {
  padding: 25px 0px;
}

.drop-zone .placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  align-self: stretch;
}
.drop-zone-wrapper {
  width: 100%;
}
.uploaded-photo-wrapper {
  position: relative;
  height: 100%;
  flex: 1 1 (20% - 10px);
  cursor: grab;
  user-select: none;
}
.uploaded-photo-wrapper:active {
  cursor: grabbing;
  z-index: 1;
}
.uploaded-photo-wrapper::after {
  position: absolute;
  content: "";
  inset: 0;
  width: 100%;
  height: 100%;
}
.drop-zone .uploaded-photo {
  width: 100%;
  height: 100%;
}
.drop-zone .uploaded-photo img {
  display: block;
  border-radius: 4px;
  max-height: 100%;
  max-width: 100%;
}
.drop-zone .uploaded-photo button {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='24' viewBox='0 0 25 24' fill='none'%3E%3Ccircle cx='12.25' cy='12' r='11' fill='%23353945' stroke='%23F4F5F6' stroke-width='2'/%3E%3Crect x='7.25' y='11' width='10' height='2' rx='1' fill='white'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  z-index: 1;
}
.drop-zone .uploaded-photo button:hover {
  transform: translate(50%, -50%) scale(1.1);
}
.drop-zone .uploaded-photo button:active {
  transform: translate(50%, -50%) scale(0.9);
}
.drop-zone-container span {
  color: var(--Neutrals-5, #959bab);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.065px;
}
.drop-zone-info {
  display: flex;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
/* REACT TOASTIFY */
.Toastify__toast-theme--light {
  border: 1px solid var(--Neutrals-8, #e6e8ec);
}
/* REACT COLOR PICKER */
.react-colorful {
  width: 100% !important;
}
.react-colorful__saturation {
  border-radius: 6px !important;
  border: 1px solid var(--Neutrals-8, #e6e8ec) !important;
}
.react-colorful .react-colorful__hue {
  order: -1;
  margin-bottom: 30px;
  height: 20px;
  border-radius: 500px;
}
.react-colorful__pointer {
  border-width: 4px !important;
}

/* MODAL  */
.modal-bg {
  position: fixed;
  display: flex;
  inset: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--Overlay-BG, rgba(35, 38, 47, 0.75));
  z-index: 5;
}
.modal-body {
  max-height: calc(100% - 130px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 20px;
  background: var(--Neutrals-11-White, #fff);
  position: relative;
}
.modal-close{
  position: absolute;
  top: 30px;
  right: 30px;
  width: 24px;
  height: 24px;
}
.modal-close::after{
  content: '';
  width: 200%;
  height: 200%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);

}

/* PHOTO */
.photo-placeholder {
  width: 100%;
  height: 100%;
  position: relative;
  border: 1px solid var(--Neutrals-8, #e6e8ec);
  background: var(--Neutrals-9, #f4f5f6);
  border-radius: 14px;
  transition: .2s;
  overflow: hidden;
}
.photo-placeholder img {
  position: absolute;
  inset: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.photo-placeholder span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--Neutrals-5, #959bab);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.065px;
}
.photo-placeholder i {
  display: block;
  position: absolute;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.00044 20.5C9.26566 20.5 9.52001 20.3946 9.70755 20.2071C9.89509 20.0196 10.0004 19.7652 10.0004 19.5V11.4225L22.3004 23.7225C22.393 23.8154 22.503 23.8891 22.6241 23.9393C22.7453 23.9896 22.8752 24.0154 23.0063 24.0152C23.1375 24.0151 23.2673 23.989 23.3883 23.9385C23.5094 23.888 23.6192 23.8141 23.7116 23.7209C23.8039 23.6278 23.8769 23.5174 23.9264 23.3959C23.9759 23.2745 24.0009 23.1444 24 23.0133C23.999 22.8821 23.9722 22.7525 23.9209 22.6317C23.8696 22.511 23.795 22.4016 23.7013 22.3099L11.3914 10H19.5004C19.7657 10 20.02 9.89464 20.2075 9.70711C20.3951 9.51957 20.5004 9.26522 20.5004 9C20.5004 8.73478 20.3951 8.48043 20.2075 8.29289C20.02 8.10536 19.7657 8 19.5004 8H9.00044C8.73523 8 8.48087 8.10536 8.29333 8.29289C8.1058 8.48043 8.00044 8.73478 8.00044 9V19.5C8.00044 19.7652 8.1058 20.0196 8.29333 20.2071C8.48087 20.3946 8.73523 20.5 9.00044 20.5Z' fill='%23D5D8DE'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  width: 32px;
  height: 32px;
  background-position: center;
  top: 0;
  left: 0;
}
.photo-placeholder i:nth-child(2) {
  right: 0;
  left: auto;
  transform: rotate(90deg);
}
.photo-placeholder i:nth-child(3) {
  top: auto;
  left: auto;
  right: 0;
  bottom: 0;
  transform: rotate(180deg);
}
.photo-placeholder i:nth-child(4) {
  top: auto;
  bottom: 0;
  transform: rotate(-90deg);
}

.line-chart-wrapper{
  width: 100%;
  height: 100%;
  background-color: teal;
}