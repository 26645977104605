.body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}
.tabs {
  display: flex;
  align-items: flex-start;
  gap: 5px;
}
.tab {
  display: flex;
  height: 28px;
  padding: 7px 10px;
  align-items: center;
  gap: 5px;
  color: var(--Neutrals-4, #777e90);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.065px;
  border-radius: 6px;
}
.currentTab {
  background: var(--Neutrals-3, #353945);

  /* Elevation 3 */
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  color: var(--Neutrals-11-White, var(--White, #fff));
}
.blocksWrapper {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}
.blocksWrapper *{
  transition: .3s;
}
.blocksWrapper[data-loading="true"] .hintBody{
  opacity: 0 !important;
}
.blocksWrapper[data-loading="true"] .block *{
  /* opacity: 0; */
  position: relative;
  color: transparent;
  stroke: transparent;
  fill: transparent;
  background-image: none;
}
.blocksWrapper[data-loading="true"] .block h3:after,
.blocksWrapper[data-loading="true"] .block p:after,
.blocksWrapper[data-loading="true"] .block i:after,
.blocksWrapper[data-loading="true"] .blockIcon:after{
  content: '';
  position: absolute;
  width: 120%;
  left: 0;
  transform: translate(0,0);
  height: 100%;
  border-radius: 4px;
  background: var(--Neutrals-8, #E6E8EC);
  z-index: 1;
  animation: Skeleton 0.5s linear infinite;
}
@keyframes Skeleton {
  0%{
    opacity: 0.5;
  }
  50%{
    opacity: 1;
  }
  100%{
    opacity: 0.5;
  }
}
.block {
    position: relative;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--Neutrals-8, #e6e8ec);
  background: var(--White, #fff);
}
.block:has(.blockHint) {
  cursor: pointer;
}
.block:has(.blockHint):hover .blockTitle{
  color: var(--Neutrals-1, #141416);
}
.block:hover .hintBody {
  opacity: 1;
  z-index: 1;
  width: max-content;
  display: block;
  transform: translate(-50%, -100%);
}
.blockTitle {
  color: var(--Neutrals-1, #141416);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 133.333% */
  letter-spacing: -0.075px;
}
.blockTitle__green {
  color: var(--Primary-SWiP-Green-Dark, #06B76E) !important;
}
.blockTitle__yellow {
  color: #eb8f25 !important;
}
.blockIcon{
    position: absolute !important;
    top: 20px;
    right: 20px;
}
.blockValue {
  color: var(--Neutrals-1, #141416);
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.blockDesc {
  color: var(--Neutrals-5, #959bab);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.065px;
}
.blockCurrency {
  color: var(--Neutrals-6, #b1b5c3);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
}
.blockHint {
  display: block;
  width: 12px;
  height: 12px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'%3E%3Cg opacity='0.5' clip-path='url(%23clip0_1150_355)'%3E%3Cpath d='M6 8V6M6 4H6.005M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z' stroke='%23141416' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1150_355'%3E%3Crect width='12' height='12' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.hintBody {
  transition: 0.2s opacity;
  top: -100%;
  transform: translate(-50%, 100%);
  left: 50%;
  opacity: 0;
  position: absolute;
  color: var(--Neutrals-11-White, var(--White, #fff));
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.065px;
  display: flex;
  padding: 10px 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: none;
  gap: 5px;
  z-index: -1;
  border-radius: 6px;
  background: var(--Neutrals-2, #23262f);
}
.hintBody::after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='7' viewBox='0 0 14 7' fill='none'%3E%3Cpath d='M5.58579 5.58579C6.36684 6.36684 7.63317 6.36683 8.41421 5.58579L14 -4.76837e-07H0L5.58579 5.58579Z' fill='%2323262F'/%3E%3C/svg%3E");
  width: 14px;
  height: 7px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);
}
