.body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}
.body[data-loading="true"] .downloadBtn *,
.countText span[data-loading="true"],
.body[data-loading="true"] thead td * {
  opacity: 0;
  position: relative;
  color: transparent;
}
.body[data-loading="true"] .downloadBtn::after,
.body[data-loading="true"] thead td::after {
  content: "";
  position: absolute;
  width: calc(100% - 20px);
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  height: 20%;
  border-radius: 2px;
  background: var(--Neutrals-8, #e6e8ec);
  animation: Skeleton 0.7s linear infinite;
}
.countText span[data-loading="true"] {
  background: var(--Neutrals-8, #e6e8ec);
  border-radius: 5px;
  animation: Skeleton 0.7s linear infinite;
}

.searchRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.inputContainer {
  position: relative;
}
.inputContainer::before {
  content: "";
  position: absolute;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.31199 7.24795C3.31199 5.07418 5.07418 3.31199 7.24795 3.31199C9.42173 3.31199 11.1839 5.07418 11.1839 7.24795C11.1839 9.42173 9.42173 11.1839 7.24795 11.1839C5.07418 11.1839 3.31199 9.42173 3.31199 7.24795ZM7.24795 2C4.34959 2 2 4.34959 2 7.24795C2 10.1463 4.34959 12.4959 7.24795 12.4959C8.46276 12.4959 9.58116 12.0831 10.4706 11.3902C10.488 11.413 10.5071 11.4349 10.528 11.4557L12.8802 13.8079C13.1364 14.0641 13.5518 14.0641 13.8079 13.8079C14.0641 13.5518 14.0641 13.1364 13.8079 12.8802L11.4557 10.528C11.4349 10.5071 11.413 10.488 11.3902 10.4706C12.0831 9.58116 12.4959 8.46276 12.4959 7.24795C12.4959 4.34959 10.1463 2 7.24795 2Z' fill='%23B1B5C3'/%3E%3C/svg%3E");
  width: 16px;
  height: 16px;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.loadingIcon {
  position: absolute;
  top: 7px;
  right: 10px;
  width: 20px;
  height: 20px;
}
.loadingIcon svg {
  width: 20px;
  height: 20px;
  filter: contrast(3);
}
.input {
  display: flex;
  height: 36px;
  padding: 6px 10px 6px 34px;
  align-items: center;
  gap: 8px;
  min-width: 500px;
  flex: 1 0 0;
  border-radius: 6px;
  border: 1px solid var(--Neutrals-8, #e6e8ec);
  background: var(--White, #fff);
}
.searchRowActions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  flex: 1 0 0;
}
.countText {
  color: var(--Neutrals-4, #777e90);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.downloadBtn {
  color: var(--Neutrals-3, #353945);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.075px;
  position: relative;
  display: flex;
  height: 36px;
  padding: 10px 12px;
  align-items: center;
  gap: 10px;
  width: 36px;
  height: 36px;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid var(--Neutrals-8, #e6e8ec);
  background: var(--White, #fff);

  /* Elevation 3 */
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
}
.tableRowPlaceholder{
  
}
.tableRowPlaceholder *{
  color: transparent !important;
  position: relative;
  width: fit-content;
}
.tableRowPlaceholder p::after,.tableRowPlaceholder span::after{
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 50%;
  border-radius: 2px;
  background: var(--Neutrals-8, #e6e8ec);
  animation: Skeleton 0.7s linear infinite;
}
.tableRowPlaceholder div{
  border-radius: 43px;
  padding: 7px 10px;
  background: var(--Neutrals-8, #f8f8f8);
}
.tableWrapper {
  border: 1px solid var(--Neutrals-8, #e6e8ec);
  border-radius: 8px;
  width: 100%;
}
.table {
  border-radius: inherit;
  border-collapse: collapse;
  width: 100%;
  background: var(--White, #fff);
}
.table thead tr {
  transition: 0.2s;
  position: sticky;
  top: 0;
  left: 0;
  background: var(--White, #fff);
  z-index: 1;
}
.table thead tr.isSticky {
  border: 1px solid var(--Neutrals-8, #e6e8ec);
  background: var(--Neutrals-9, #f4f5f6);
  box-shadow: 0px 0px 10px 0px rgba(15, 15, 15, 0.1);
}

.table thead td {
  position: relative;
  color: var(--Neutrals-5, #959bab);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.065px;
  height: 50px;
  padding: 15px 20px;
  gap: 20px;
  align-self: stretch;
}
.table tbody tr td:last-child {
  padding: 15px 20px;
  position: relative;
}
.table tbody tr td:last-child::after {
  content: "";
  display: block;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8' fill='none'%3E%3Cpath d='M3 7L5.64645 4.35355C5.84171 4.15829 5.84171 3.84171 5.64645 3.64644L3 1' stroke='%23353945' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E");
  width: 8px;
  height: 8px;
  transition: 0.2s;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 20px;
  opacity: 0.5;
}
.table tbody tr {
  transition: 0.2s;
}
.table tbody tr:hover {
  cursor: pointer;
  background: var(--Neutrals-9, #f4f5f6);
}
.table tbody tr:hover td:last-child::after {
  right: 15px;
  opacity: 1;
}

.table tbody td,
.tableBottom {
  padding: 15px 10px 15px 20px;
  border-top: 1px solid var(--Neutrals-8, #e6e8ec);
}
.table p {
  color: var(--Neutrals-3, #353945);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.15px;
  display: flex;
  gap: 3px;
}
.table span {
  color: var(--Neutrals-4, #777e90);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.065px;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  margin-top: 3px;
}

.tagList {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 4px 2px;
  flex: 1 0 0;
  flex-wrap: wrap;
}
.table .tagList {
  max-width: 150px;
}
.tag {
  display: flex;
  padding: 3px 8px;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 3px;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.06px;
  border-radius: 43px;
  color: var(--Neutrals-4, #777e90);
  background: rgba(119, 126, 144, 0.1);
}
.tag::after{
  content: '';
  display: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 12px;
  height: 12px;
}
.tag__green {
  color: var(--Primary-SWiP-Green-Dark, #06b76e) !important;
  background: rgba(0, 140, 79, 0.15);
}
.tag__orange {
  color: #eb8f25 !important;
  background: rgba(235, 143, 37, 0.15);
}
.tag__purple {
  color: #af52de !important;
  background: rgba(175, 82, 222, 0.15);
}
.tag__purple::after{
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_3957_71975)'%3E%3Cpath d='M6 8.5V6.5M6 4.5H6.005M11 6.5C11 9.26142 8.76142 11.5 6 11.5C3.23858 11.5 1 9.26142 1 6.5C1 3.73858 3.23858 1.5 6 1.5C8.76142 1.5 11 3.73858 11 6.5Z' stroke='%23AF52DE' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_3957_71975'%3E%3Crect width='12' height='12' fill='white' transform='translate(0 0.5)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  display: block;
}
.tag__orange::after{
  display: block;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_3957_66193)'%3E%3Cpath d='M6 8.5V6.5M6 4.5H6.005M11 6.5C11 9.26142 8.76142 11.5 6 11.5C3.23858 11.5 1 9.26142 1 6.5C1 3.73858 3.23858 1.5 6 1.5C8.76142 1.5 11 3.73858 11 6.5Z' stroke='%23EB8F25' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_3957_66193'%3E%3Crect width='12' height='12' fill='white' transform='translate(0 0.5)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}
@keyframes Skeleton {
  0%{
    opacity: 0.5;
  }
  50%{
    opacity: 1;
  }
  100%{
    opacity: 0.5;
  }
  
}
.tagAIHintWrapper *{
  transition: .2s;
}
.tagAIHintWrapper[data-loading="true"] *{
  color: transparent;
  position: relative;
  fill: transparent;
  stroke: transparent;
  background: transparent;
}
.tagAIHintWrapper[data-loading="true"] svg{
  background: var(--Neutrals-8, #e6e8ec);
  border-radius: 2px;
  animation: Skeleton 1s linear infinite;
}
.tagAIHintWrapper[data-loading="true"] h3:after,
.tagAIHintWrapper[data-loading="true"] span:after,
.tagAIHintWrapper[data-loading="true"] .tagAIHintDesc:after{
  content: "";
  position: absolute;
  width: calc(100% - 20px);
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  border-radius: 2px;
  background: var(--Neutrals-8, #e6e8ec);
  animation: Skeleton 0.7s linear infinite;
}
.tag:hover .tagAIHintWrapper{
  z-index: 10;
  top: 0;
opacity: 1;
}
.tag:hover .tagAIHintWrapper[data-bottom-position="true"]{
  top: auto;
opacity: 1;
bottom: 0;
}
.tagAIHintWrapper{
  transition: .2;
  position: absolute;
  top: 30px;
left: 50%;
transform: translate(-50%,calc(-100% - 20px));
display: flex;
padding: 25px;
justify-content: center;
align-items: flex-start;
gap: 15px;
flex-direction: column;
border-radius: 6px;
min-width: 350px;
background: var(--Neutrals-11-White, #FFF);
box-shadow: 0px 25px 41.3px 0px rgba(0, 0, 0, 0.15);
z-index: -1;
opacity: 0;
}
.tagAIHintWrapper[data-bottom-position="true"]{
  top: auto;
  bottom: -30px;
transform: translate(-50%,calc(100% - 20px));
}
.tagAIHintWrapper[data-bottom-position="true"]::after{
  bottom:auto;
  top: 0;
  transform: translate(-50%,-100%) rotate(180deg);
}
.tagAIHintWrapper::after{
  content: '';
  background-image: url("data:image/svg+xml,%3Csvg width=%2714%27 height=%277%27 viewBox=%270 0 14 7%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath d=%27M5.58579 5.58579C6.36684 6.36684 7.63317 6.36683 8.41421 5.58579L14 -4.76837e-07H0L5.58579 5.58579Z%27 fill=%27white%27/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 40px;
  position: absolute;
  left: 50%;
  background-position: top;
  bottom: 0;
  transform: translate(-50%,100%);
}
.tagAIHintHeader{
  display: flex;
  align-items: center;
  gap: 15px;
  align-self: stretch;
}
.tagAIHintHeader h3{
  color: var(--Neutrals-3, #353945);
font-size: 17px;
font-weight: 700;
line-height: 18px; /* 105.882% */
}
.tagAIHintDesc{
  align-self: stretch;
  color: var(--Neutrals-3, #353945);
font-size: 13px;
line-height: 18px; /* 138.462% */
}
.tagAIHintFooter{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
  align-self: stretch;
  
}
.tagAIHintFooter div{
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  
}
.tagAIHintFooter div *{
  color: var(--Neutrals-4, #777E90);
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 18px; /* 138.462% */
}
.tagAIHintFooter div p{
  color: var(--Neutrals-3, #353945);
}
.tagAIHintCounter{
  display: flex;
  align-items: flex-start;
  gap: 8px;
}
.tagAIHintCounter li{
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--Neutrals-7, #D5D8DE);
}
.tagAIHintCounter li.current{
  background: var(--Neutrals-1, #141416);
}
.tagAIHintCategory{
  display: flex;
padding: 3px 8px;
justify-content: center;
align-items: center;
gap: 3px;
border-radius: 43px;
background: var(--Primary-SWiP-Green-Dark, #06B76E);
color: var(--Neutrals-11-White, #FFF) !important;
font-size: 12px !important;
font-weight: 500 !important;
letter-spacing: -0.06px !important;
}
.cellPrices span {
  text-decoration: line-through;
}


/* --------------------------------- TRANSACTION OVERVIEW --------------------------------------- */

.overviewBody *{
  transition: .2s;
}
.overviewBody {
  width: 900px;
  padding: 30px;
  height: 100%;
}
.overviewBody[data-loading="true"] *:not(h2){
  position: relative;
  color: transparent !important;
  background-color: transparent;
}
.overviewBody[data-loading="true"] * span::after,
.overviewBody[data-loading="true"] * p::after,
.overviewBody[data-loading="true"] * td::after,
.overviewBody[data-loading="true"] .overviewStatus::after{
  content: "";
  position: absolute;
  width: calc(100% - 20px);
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  height: 50%;
  border-radius: 2px;
  background: var(--Neutrals-8, #e6e8ec);
  animation: Skeleton 0.7s linear infinite;
}
.overviewBodyContent{
  overflow: auto;
  height: 100%;
  display: flex;
  padding-right: 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}
.overviewBodyContent::-webkit-scrollbar {
  width: 8px; /* Устанавливает ширину скроллбара */
  background: transparent; /* Фон скроллбара делаем прозрачным */
}

.overviewBodyContent::-webkit-scrollbar-track {
  border-radius: 20px;
  background: var(--Neutrals-9, #f4f5f6);
}

.overviewBodyContent::-webkit-scrollbar-thumb {
  background: var(--Neutrals-5, #959bab);
  border-radius: 11px;
}
.overviewBodyHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.overviewStatus {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--Neutrals-3, #353945);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.15px;
}
.overviewStatus i {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #06b76e;
}
.overviewTitleContainer{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.overviewCopyBtn{
padding: 6px 8px !important;
align-items: center;
flex-direction: row-reverse;
gap: 5px;
}
.overviewTitle {
  color: var(--Neutrals-1, #141416);
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.overviewTable {
  border-collapse: collapse;
  width: 100%;
}
.overviewTable td{
  color: var(--Neutrals-3, #353945);
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.15px;
}
.overviewTable tr {
  position: relative;
  z-index: 1;
}
.overviewTable tr:nth-child(even)::after{
  border-radius: 4px;
  content: '';
  inset: 0;
  position: absolute;
  z-index: -1;
  background: var(--Neutrals-9, #f4f5f6);
}
.overviewTable tr td{
  padding: 6px 10px;
}
.overviewTable tr td:first-child{
  width: 250px;
  color: var(--Neutrals-4, #777E90);
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.075px;
}
.overviewSubTitle {
  display: flex;
padding-top: 15px;
align-items: flex-start;
gap: 10px;
align-self: stretch;
color: var(--Neutrals-1, #141416);
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.hintColor{
  color: var(--Neutrals-4, #777E90);

}
.tagRating{
  display: flex;
align-items: center;
gap: 5px;
}
.overviewTablePurchases td{
  width: auto !important;
}
.discountList{
  display: flex;
align-items: center;
align-content: center;
gap: 10px;
flex: 1 0 0;
flex-wrap: wrap;
}
.discountList li{
  display: flex;
padding: 3px 3px 3px 10px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 43px;
background: var(--Control-Grey, rgba(177, 181, 195, 0.15));
color: var(--Neutrals-3, #353945);
font-family: Inter;
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.065px;
position: relative;
cursor: pointer;
}
.discountList li span{
  color: var(--Neutrals-4, #777E90);
}